@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.scroll-animate {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 0.8s ease-out;
}

.scroll-animate.animate {
  opacity: 1;
  transform: translateX(0);
  animation: appear 1s forwards;
}

/* Variaciones de animación */
.scroll-animate.fade-in.animate {
  animation: appear 1s forwards;
}

.scroll-animate.slide-left.animate {
  transform: translateX(0);
  animation: slide-left 1s forwards;
}

.scroll-animate.slide-up.animate {
  transform: translateY(0);
  animation: slide-up 1s forwards;
}

/* Animaciones adicionales */
@keyframes slide-left {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
