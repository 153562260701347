.topbar {
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(0 56 147 / 70%);
  padding: 10px 5em;
  transition: top 0.3s;
  z-index: 2;
}

.hidden {
  top: -60px; /* Altura suficiente para ocultar el topbar */
}

.logo-tob-bar {
  height: 40px;
  width: 150px;
}

.socialIcons {
  display: flex;
  gap: 10px;
}
.socialIcons svg {
  height: 25px;
  color: white;
}
@media (max-width: 1000px) {
  .topbar {
    padding: 0.3rem 1rem;
  }
}
