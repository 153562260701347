:root {
  --white: #fff;
  --black: #2e2b29;
  --black-contrast: #110f0e;
  --gray-1: rgba(61, 37, 20, 0.05);
  --gray-2: rgba(61, 37, 20, 0.08);
  --gray-3: rgba(61, 37, 20, 0.12);
  --gray-4: rgba(53, 38, 28, 0.3);
  --gray-5: rgba(28, 25, 23, 0.6);
  --green: #22c55e;
  --purple: #6a00f5;
  --purple-contrast: #5800cc;
  --purple-light: rgba(88, 5, 255, 0.05);
  --yellow-contrast: #facc15;
  --yellow: rgba(250, 204, 21, 0.4);
  --yellow-light: #fffae5;
  --red: #ff5c33;
  --red-light: #ffebe5;
  --shadow: 0px 12px 33px 0px rgba(0, 0, 0, 0.06),
    0px 3.618px 9.949px 0px rgba(0, 0, 0, 0.04);
}
.container-tipTap {
  box-shadow: 0 0 20px #eee;
  padding: 2em 1em;
  border-radius: 1rem;
  margin: 0 auto;
  max-width: 90%;
}
.custom-editor-container .ProseMirror {
  border: 1px dashed #d1d5db;
  padding: 1em;
  border-radius: 0.5em;
}
.container-input-tiptap {
  display: grid;
  gap: 10px;
  color: var(--azul-gac);
  font-weight: 600;
  padding: 0 1.5rem;
}
.container-input-tiptap.add {
  grid-template-columns: 90% 10%;
}
.input-tiptap {
  border: 1px dashed #d1d5db;
  border-radius: 0.5em;
  min-height: 50px;
  outline: none;
  padding: 0 1em;
}
.input-tiptap:focus {
  border: 1px solid var(--purple);
}

.tiptap :first-child {
  margin-top: 0;
}

/* List styles */
.tiptap ul,
.tiptap ol {
  padding: 0 1rem;
  margin: 1.25rem 1rem 1.25rem 0.4rem;
}

.tiptap ul li p,
.tiptap ol li p {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

/* Heading styles */
.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  line-height: 1.1;
  margin-top: 2.5rem;
  text-wrap: pretty;
}

.tiptap h1,
.tiptap h2 {
  margin-top: 3.5rem;
  margin-bottom: 1.5rem;
}

.tiptap h1 {
  font-size: 1.4rem;
}

.tiptap h2 {
  font-size: 1.2rem;
}

.tiptap h3 {
  font-size: 1.1rem;
}

.tiptap h4,
.tiptap h5,
.tiptap h6 {
  font-size: 1rem;
}

/* Code and preformatted text styles */
.tiptap code {
  background-color: var(--purple-light);
  border-radius: 0.4rem;
  color: var(--black);
  font-size: 0.85rem;
  padding: 0.25em 0.3em;
}

.tiptap pre {
  background: var(--black);
  border-radius: 0.5rem;
  color: var(--white);
  font-family: "JetBrainsMono", monospace;
  margin: 1.5rem 0;
  padding: 0.75rem 1rem;
}

.tiptap pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

.tiptap blockquote {
  border-left: 3px solid var(--gray-3);
  margin: 1.5rem 0;
  padding-left: 1rem;
}

.tiptap hr {
  border: none;
  border-top: 1px solid var(--gray-2);
  margin: 2rem 0;
}

.tiptap {
  caret-color: var(--purple);
  margin: 1.5rem;
}

.tiptap:focus {
  outline: none;
}

.buttonTipTap {
  background: var(--gray-2);
  border-radius: 0.5rem;
  border: none;
  color: var(--black);
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.15;
  margin: none;
  padding: 0.375rem 0.625rem;
  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.buttonTipTap:hover {
  background-color: var(--gray-3);
  color: var(--black-contrast);
}

.buttonTipTap[disabled] {
  background: var(--gray-1);
  color: var(--gray-4);
}

.buttonTipTap.is-active {
  background: var(--azul-gac);
  color: var(--white);
}

.buttonTipTap.is-active:hover {
  background-color: var(--azul-gac);
  color: var(--white);
}

.buttonTipTap:not([disabled]) {
  cursor: pointer;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.control-group {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
}
/* Basic editor styles */
.tiptap :first-child {
  margin-top: 0;
}

/* Table-specific styling */
.tiptap table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}

.tiptap table td,
.tiptap table th {
  border: 1px solid var(--gray-3);
  box-sizing: border-box;
  min-width: 1em;
  padding: 6px 8px;
  position: relative;
  vertical-align: top;
}

.tiptap table td > *,
.tiptap table th > * {
  margin-bottom: 0;
}

.tiptap table th {
  background-color: var(--gray-1);
  font-weight: bold;
  text-align: left;
}

.tiptap table .selectedCell:after {
  background: var(--gray-2);
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.tiptap table .column-resize-handle {
  background-color: var(--purple);
  bottom: -2px;
  pointer-events: none;
  position: absolute;
  right: -2px;
  top: 0;
  width: 4px;
}

.tiptap .tableWrapper {
  margin: 1.5rem 0;
  overflow-x: auto;
}

.tiptap.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
