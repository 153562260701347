.main__content {
  max-width: 80rem;
  margin: 0 auto;
  border-radius: 1em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  min-height: 50vh;
}
.containerTyC {
  margin: 180px auto;
  max-width: 1200px;
  width: 100%;
}
.title__tyc {
  color: rgb(17 24 39);
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
}
.container-__accordion {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90%, 1fr));
  gap: 2em;
}
.acoordionTyc {
  border: none;
}
.accordion__headerTyc {
  color: #34373b;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  padding: 10px 20px;
  text-transform: uppercase;
  border-bottom: 1px solid;
}
.accordion__tyc-content {
  max-height: 0;
  overflow: hidden;
  color: #34373b;
  text-align: justify;
}
.accordion__tyc-content.open {
  max-height: -webkit-fit-content;
  max-height: fit-content;
  padding: 2em;
}
.term-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.term-card {
  border: 1px solid #e2e8f0;
  border-radius: 1em;
  padding: 16px;
  transition: border-color, transform 0.3s ease;
}

.term-card:hover {
  border-color: var(--azul-gac);
  transform: scale(1.03);
}

.term-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.term-title {
  font-weight: 500;
  color: rgb(17 24 39);
  font-size: inherit;
}

.action-buttons {
  display: flex;
  gap: 8px;
}
.term-content {
  line-height: 1.25rem;
  color: rgb(75 85 99);
  margin-bottom: 0.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.term-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: #6b7280;
}

.status {
  font-size: 0.55rem;
  font-weight: 500;
}
.status.active {
  color: #062865;
  border-bottom: 1px solid;
}

.btn-tyc {
  padding: 10px;
  border-radius: 12px;
  border: none;
  color: #ff3e4e;
  transition: all 0.25s ease;
}
.btn-tyc svg {
  width: 20px;
  height: 20px;
}

.btn-tyc:hover {
  box-shadow: 0 10px 20px -10px rgba(136, 135, 135, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
  color: #fff;
}
.btn-tyc.edit {
  color: var(--azul-gac);
}
.btn-tyc.edit:hover {
  background: var(--azul-gac);
  color: #fff;
}
.accordion__tyc-text table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}
.accordion__tyc-text table td,
.accordion__tyc-text table th {
  border: 1px solid var(--gray-3);
  box-sizing: border-box;
  min-width: 1em;
  padding: 6px 8px;
  position: relative;
  vertical-align: top;
}

.accordion__tyc-text table td > *,
.accordion__tyc-text table th > * {
  margin-bottom: 0;
}

.accordion__tyc-text table th {
  background-color: var(--gray-1);
  font-weight: bold;
  text-align: left;
}
.accordion__tyc-text ul,
.accordion__tyc-text ol {
  padding: 0 1rem;
  margin: 1.25rem 1rem 1.25rem 0.4rem;
}

.accordion__tyc-text ul li p,
.accordion__tyc-text ol li p {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

/* Heading styles */
.accordion__tyc-text h1,
.accordion__tyc-text h2,
.accordion__tyc-text h3,
.accordion__tyc-text h4,
.accordion__tyc-text h5,
.accordion__tyc-text h6 {
  line-height: 1.1;
  margin-top: 2.5rem;
  text-wrap: pretty;
}

.accordion__tyc-text h1,
.accordion__tyc-text h2 {
  margin-top: 3.5rem;
  margin-bottom: 1.5rem;
}

.tiptap h1 {
  font-size: 1.4rem;
}

.accordion__tyc-text h2 {
  font-size: 1.2rem;
}

.accordion__tyc-text h3 {
  font-size: 1.1rem;
}

.accordion__tyc-text h4,
.accordion__tyc-text h5,
.accordion__tyc-text h6 {
  font-size: 1rem;
}

/* Code and preformatted text styles */
.accordion__tyc-text code {
  background-color: var(--purple-light);
  border-radius: 0.4rem;
  color: var(--black);
  font-size: 0.85rem;
  padding: 0.25em 0.3em;
}

.accordion__tyc-text pre {
  background: var(--black);
  border-radius: 0.5rem;
  color: var(--white);
  font-family: "JetBrainsMono", monospace;
  margin: 1.5rem 0;
  padding: 0.75rem 1rem;
}

.accordion__tyc-text pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

.accordion__tyc-text blockquote {
  border-left: 3px solid var(--gray-3);
  margin: 1.5rem 0;
  padding-left: 1rem;
}

.accordion__tyc-text hr {
  border: none;
  border-top: 1px solid var(--gray-2);
  margin: 2rem 0;
}
.icon-tyc {
  width: 30px;
  height: 30px;
  background-color: #121826;
  border-radius: 50%;
}
