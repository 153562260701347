.custom-select {
  width: 100%;
  padding: 10px 0;
  border: none;
  background: transparent;
  border-bottom: 2px solid var(--azul-gac);
}
.custum-file-upload {
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 2px dashed #cacaca;
  background-color: rgba(255, 255, 255, 1);
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 48px 35px -48px rgba(0, 0, 0, 0.1);
  margin: 1em 0;
}

.custum-file-upload .icon_upload {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .icon_upload svg {
  width: 80px;
  height: 80px;
  fill: rgba(75, 85, 99, 1);
}

.custum-file-upload .text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .text span {
  font-weight: 400;
  color: rgba(75, 85, 99, 1);
}

.custum-file-upload input {
  display: none;
}
.container-contact {
  width: 900px;
  height: max-content;
  margin: 250px 20px;
}
.contenedor-form {
  padding: 2.5rem;
  border-radius: 2rem;
  width: 100%;
  color: #666;
  background-color: #fff;
  border: 2px solid var(--azul-gac);
  box-shadow: 5px 5px var(--amarillo-gac);
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: var(--gris-gac);
  margin-bottom: 10px;
  border: none;
  border-bottom: 2px solid var(--azul-gac);
  outline: none;
  background: transparent;
}

.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 14px;
  color: var(--gris-gac);
  pointer-events: none;
  transition: 0.5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: var(--azul-gac);
  font-size: 12px;
}

.textArea {
  width: 100%;
  border: none;
  min-height: 100px;
  border-radius: 10px;
  padding: 0.5rem;
  border: 1px solid var(--azul-gac);
}
.textArea textarea:focus {
  border: #666;
}

.terminos {
  font-size: 14px;
}
.terminos input {
  margin: 0 0.2rem;
}

@media (max-width: 1000px) {
  .container-contact {
    width: 100%;
    margin: 150px 20px;
  }
  .contenedor-form {
    padding: 1rem;
    width: 100%;
    margin: 0 auto;
  }
}
