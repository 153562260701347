.separator {
  width: calc(100% - 20px);
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 10px;
  color: #8b8e98;
  margin: 0 10px;
}

.separator > p {
  word-break: keep-all;
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  margin: auto;
  color: #003893;
}

.separator .line {
  display: inline-block;
  width: 100%;
  height: 2px;
  border: 0;
  background-color: var(--azul-gac);
  margin: auto;
}
.form-container {
  margin: 200px auto;
  max-width: 700px;
  padding: 2rem;
  border-radius: 2rem;
  border: 1px solid #009ee3;
  box-shadow: 5px 5px var(--amarillo-gac);
  color: var(--azul-gac);
  font-weight: 500;
  font-size: 16px;
}

.inputPay {
  width: 100%;
  height: 46px;
  border-radius: 0.5em;
  background-color: #f2f2f2;
  border: none;
  padding: 0 10px;
}
.inputPay:focus {
  border: 1px solid red;
}
.MercadoPago {
  align-items: center;
  background-color: #009ee3;
  border-radius: 2rem;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-weight: 600;
  justify-content: center;
  margin: 0;
  overflow: visible;
  text-transform: none;
  width: 100%;
  height: 46px;
  border: none;
}
.MercadoPago:disabled {
  background-color: #009ee3;
  cursor: not-allowed;
}
.errorForm {
  color: var(--rojo-gac);
  font-weight: 400;
  font-size: 16px;
  display: flex;
  gap: 4px;
  align-items: center;
}

.ErrorContainer input {
  border: 2px solid var(--rojo-gac);
  margin: 0;
}
.ErrorContainer select {
  border: 2px solid var(--rojo-gac);
}

.textHeader {
  max-width: 600px;
  margin: 30px auto;
  font-size: 16px;
}
.radio-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-inputs > * {
  margin: 6px;
}

.radio-input:checked + .radio-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #2260ff;
}

.radio-input:checked + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #2260ff;
  border-color: #2260ff;
}

.radio-input:checked + .radio-tile .radio-icon svg {
  fill: #2260ff;
}

.radio-input:checked + .radio-tile .radio-label {
  color: #2260ff;
}

.radio-input:focus + .radio-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
}

.radio-input:focus + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
}

.radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  min-height: 80px;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}

.radio-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
}

.radio-tile:hover {
  border-color: #2260ff;
}

.radio-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.radio-icon svg {
  width: 2rem;
  height: 2rem;
  fill: #494949;
}

.radio-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
  font-size: 13px;
}

.radio-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.text-type {
  animation: scale-up-center 0.4s;
}
@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.card-msj-pay {
  background-color: #ffffff;
  background-image: radial-gradient(
      at 47% 33%,
      hsl(198, 100%, 45%) 0,
      transparent 59%
    ),
    radial-gradient(at 82% 65%, hsl(195, 0%, 100%) 0, transparent 55%);
}

.body-card-msj-pay {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.64);
  border-radius: 1rem;
  padding: 1rem;
}
.body-card-msj-pay ul {
  list-style: none;
}
.btn-help-whatsapp {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid var(--azul-gac);
  border-radius: 2em;
  padding: 0.5em 3em;
  color: var(--azul-gac);
  font-weight: 700;
  box-shadow: 3px 3px #25d366;
  font-size: clamp(15px, 4vw + 1rem, 17px);
  margin: 1em 0;
  transition: all 0.5s;
  width: fit-content;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.btn-help-whatsapp svg {
  width: 30px;
  height: 30px;
}

.container-pay {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
}

@media (max-width: 768px) {
  .form-container {
    margin: 140px auto;
    width: 96%;
    padding: 2rem 1rem;
  }
  .container-pay {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .container-pay > img:nth-child(3) {
    grid-column: span 2;
  }
}
