.menu-admin {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  width: 1200px;
  margin: 0 auto;
  gap: 1rem;
}
.card-container {
  background-color: white;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #eafaf8;
  transition: border 0.7s ease, background-color 0.7s ease;
  width: 380px;
}

.card-container:hover {
  border-color: #333;
  background-color: rgb(234 250 248);
}

.image-container {
  margin-bottom: 1rem;
}

.card-title {
  padding-top: 2rem;
  font-size: 1.25rem;
  font-weight: 600;
}
