.robot-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 120px;
  right: 20px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-robot {
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-robot svg {
  width: 90px;
  height: 90px;
}
.icons-container {
  position: absolute;
  right: 90px;
  bottom: 0;
  display: none;
  flex-direction: column;
}
.icons-container.show {
  display: flex;
}
.icon-item {
  padding: 0.4em;
  width: 68px;
  height: 68px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
  color: var(--amarillo-gac);
  position: absolute;
  border: 2px solid var(--azul-gac);
  opacity: 0;
  transform: scale(0.5);
  filter: blur(5px);
  z-index: 1;
}
.icon-item svg {
  width: 40px;
  height: 40px;
}
.icon-item.visible {
  opacity: 1;
  transform: scale(1);
  filter: blur(0);
}

.icon-item:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: -30px;
  left: -100%;
  background-color: var(--fondo-defecto);
  color: var(--azul-gac);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 700;
  white-space: nowrap;
  border: 2px solid var(--azul-gac);
  z-index: 9999;
}
.icon-item:nth-child(1)::after {
  left: -160px;
}
.icon-item:nth-child(3)::after {
  left: -90px;
}
.icon-item:nth-child(5)::after {
  left: -60px;
}
.icon-item:nth-child(1) {
  top: -168px;
  right: -110px;
}
.icon-item:nth-child(2) {
  top: -136px;
  right: -37px;
}
.icon-item:nth-child(3) {
  top: -62px;
  right: -2px;
}
.icon-item:nth-child(4) {
  bottom: -87px;
  right: -41px;
}
.icon-item:nth-child(5) {
  bottom: -109px;
  left: 42px;
}
.border-menu {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 6px solid transparent; /* Bordes invisibles al inicio */
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.border-menu::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-top: 6px solid var(--azul-gac);
  border-left: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid transparent;
  animation: drawBorder 3s ease-out forwards;
  box-sizing: border-box;
}

@keyframes drawBorder {
  0% {
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
  25% {
    border-left-color: var(--azul-gac);
    border-bottom-color: transparent;
  }
  50% {
    border-left-color: var(--azul-gac);
    border-bottom-color: var(--azul-gac);
  }
  75% {
    border-left-color: var(--azul-gac);
    border-bottom-color: var(--azul-gac);
    border-top-color: var(--azul-gac);
  }
  100% {
    border-left-color: var(--azul-gac);
    border-bottom-color: var(--azul-gac);
    border-right-color: var(--azul-gac);
    border-top-color: var(--azul-gac);
  }
}
