.container-payments {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin: 2rem auto;
  justify-items: center;
}
.btn-payment {
  align-items: center;
  box-shadow: 3px 2px 4px #ececec;
  display: flex;
  justify-content: space-evenly;
  padding: 0.8em 1rem;
  transition: all 0.3s;
  width: 300px;
  border: 1px solid var(--azul-gac);
  border-radius: 0.8em;
  font-weight: 600;
  margin: 0.5em 0;
}

.btn-payment.international {
  background: var(--fondo-defecto);
  color: var(--azul-gac);
}

.btn-payment.national {
  background: var(--azul-gac);
  color: var(--fondo-defecto);
}

.btn-back-step {
  border: none;
  background: var(--fondo-defecto);
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--azul-gac);
  font-weight: 700;
}
.btn_payment_payu {
  align-items: center;
  background: #88b312;
  border: none;
  border-radius: 2rem;
  box-shadow: 0 0 100px #bcb9b9;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  transition: all 0.3s;
  width: 300px;
  margin: 0 auto;
}
.btn_payment_payu img {
  height: 100%;
  padding: 8px;
}
