@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
:root {
  font-size: 20px;
}
:root {
  --azul-gac: #003893;
  --amarillo-gac: #ffcf04;
  --rojo-gac: #ed0a26;
  --gris-gac: #666;
  --oscuro: #252525;
  --fondo-defecto: #fff;
  --main-color: #8b3eea;
  --white-color: #ffffff;
  --black-color: #2b2540;
  --black-color-opacity: #2b2540c4;
  --paragraph-color: #818090;
  --bg-color: #f3f6fd;
  --transition: 0.4s all ease-in-out;
}
body {
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}
a {
  cursor: pointer;
  transition: all 200ms linear;
}
a:hover {
  text-decoration: none;
}
tbody,
td,
tfoot,
th {
  border: 1px solid var(--bg-color);
}

table {
  text-align: center;
  width: 100%;
}
tbody,
td,
tfoot,
th {
  border: 1px solid var(--azul-gac);
}
table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}
table td,
table th {
  border: 1px solid var(--gray-3);
  box-sizing: border-box;
  min-width: 1em;
  padding: 6px 8px;
  position: relative;
  vertical-align: top;
}

table td > *,
table th > * {
  margin-bottom: 0;
}

table th {
  background-color: var(--gray-1);
  font-weight: bold;
  text-align: left;
}
.text-justify {
  text-align: justify;
}
.modal-body {
  padding: 0;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: hsla(0, 0%, 100%, 0);

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.nuestrocontenedor {
  position: relative;
}
.nuestrocontenedor-active {
  position: relative;
}
.nuestraimagen {
  display: block;
  width: 100%;
  height: auto;
}
.nuestrobloque {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.4s ease;
  background-color: rgb(0, 56, 147, 0.7);
  backdrop-filter: blur(3px);
  border-radius: 50%;
  cursor: pointer;
}
.nuestrotexto {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}
.nuestrocontenedor-active .nuestrobloque {
  opacity: 1;
}
.nuestrocontenedor:hover .nuestrobloque {
  opacity: 1;
}
.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--swiper-theme-color);
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}
* {
  margin: 0;
  padding: 0;
}

.centrar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-gac {
  /* background: #f8d247; */
  /* width: 90%; */
  /* margin: 1rem; */
  /* border-radius: 2rem; */
  color: white;
  padding: 0.7rem;
  transition: all 0.4s ease-in;
}

.navbar-gac-scroll {
  background: rgb(255 255 255 / 1);
  backdrop-filter: blur(10px);
  /* box-shadow: 0 2px 10px #e6e6e6; */
  position: sticky;
  top: 0rem;
  z-index: 1024;
}
.navlink {
  color: var(--azul-gac) !important;
  transition: all 0.3s;
}
.navlink-active {
  color: #252525;
}
.cardHotels {
  display: flex;
  align-items: center;
  width: 75vw;
  max-width: 650px;
  padding: 50px 30px 50px 20px;
  background: #ffffff;
  border-radius: 24px;
}
.cardHotels img {
  max-width: 280px;
  width: 28vw;
  height: 300px;
  object-fit: cover;
  margin-left: -60px;
  margin-right: 30px;
  border-radius: inherit;
  box-shadow: 0 60px 40px rgb(0 0 0 / 8%);
}
.cardHotels h2 {
  font-size: 22px;
  margin-right: 30px;
}

.cardHotels h3 {
  font-size: 16px;
  font-weight: 400;
  color: rgb(0 0 0 / 90%);
}

.cardHotels p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(0 0 0 / 70%);
  margin-bottom: 24px;
}

.cardHotels button {
  border: 0;
  background: linear-gradient(90deg, #5448de, #5448de);
  color: #f8f8f8;
  font-family: inherit;
  padding: 16px 26px;
  font-size: 16px;
  border-radius: 40px;
}

/* @media (width <= 740px) { */
.cardHotels {
  margin: 0 40px;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  text-align: center;
  flex-direction: column;
}

.cardHotels h2 {
  margin-right: 0;
  font-size: 26px;
}

.cardHotels img {
  margin: -100px 0 30px 0;
  width: 100%;
  max-width: 1000px;
}

.link {
  color: #c4c3ca;
}
.link:hover {
  color: #ffeba7;
}
.over-hide {
  overflow: hidden;
}
.size-22 {
  font-size: 22px;
}
.full-height {
  min-height: 100vh;
}

.block-diff {
  display: block;
  mix-blend-mode: difference;
}

.card-3d-wrap {
  position: relative;
  width: 340px;
  max-width: calc(100% - 20px);
  height: 510px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  perspective: 1000px;
  margin-top: 90px;
}
.card-3d-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: transform 700ms 400ms ease-out;
}
.card-front,
.card-back {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  border-radius: 6px;
  left: 0;
  top: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 0 12px 35px 0 rgba(16, 39, 112, 0.07);
}
.card-back {
  transform: rotateY(180deg);
}
.pricing:checked ~ .card-3d-wrap .card-3d-wrapper {
  transform: rotateY(180deg);
  transition: transform 700ms 400ms ease-out;
}
.pricing-wrap {
  position: relative;
  padding-top: 160px;
  width: 100%;
  display: block;
  z-index: 1;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
.pricing-wrap h4 {
  position: relative;
  width: 100%;
  display: block;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  font-size: 22px;
  line-height: 1.7;
  color: #102770;
  transform: translate3d(0, 0, 35px) perspective(100px);
}
.pricing-wrap h4:before {
  position: absolute;
  content: "";
  z-index: -1;
  background: linear-gradient(217deg, #448ad5, #b8eaf9);
  width: 70px;
  height: 70px;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 6px 20px 0 rgba(16, 39, 112, 0.3);
  animation: border-transform 6s linear infinite;
}
.card-back .pricing-wrap h4:before {
  background: linear-gradient(217deg, #648946, #a3c984);
}
@keyframes border-transform {
  0%,
  100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}
.pricing-wrap h2 {
  position: relative;
  width: 100%;
  display: block;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 36px;
  line-height: 1.1;
  color: #102770;
  transform: translate3d(0, 0, 30px) perspective(100px);
}
.pricing-wrap h2 sup {
  font-size: 20px;
}
.pricing-wrap p {
  position: relative;
  width: 100%;
  display: block;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 1px;
  color: #102770;
  transform: translate3d(0, 0, 30px) perspective(100px);
}
.card-back h2,
.card-back p,
.card-back h4 {
  color: #0c1c00;
}
.link {
  position: relative;
  padding: 10px 20px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  color: #ffeba7;
  background-color: #102770;
  transition: all 200ms linear;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  transform: translate3d(0, 0, 30px) perspective(100px);
}
.link:hover {
  color: #102770;
  background-color: #ffeba7;
}
.card-back .link {
  background-color: #0c1c00;
}
.card-back .link:hover {
  color: #0c1c00;
  background-color: #ffeba7;
}
.img-wrap img {
  width: 100%;
  height: auto;
  display: block;
}
.img-1 {
  position: absolute;
  display: block;
  left: -160px;
  top: -50px;
  z-index: 5;
  width: calc(80% + 160px);
  transform: translate3d(0, 0, 45px) perspective(100px);
  opacity: 1;
  pointer-events: auto;
  transition: transform 400ms 1200ms ease, opacity 400ms 1200ms ease;
}
.pricing:checked ~ .card-3d-wrap .card-3d-wrapper .img-1 {
  opacity: 0;
  transform: translate3d(-50px, 25px, 45px) perspective(100px);
  pointer-events: none;
  transition: transform 400ms ease, opacity 200ms 150ms ease;
}
.img-2 {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  z-index: 1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  width: 100%;
  transform: translate3d(0, 0, 15px) perspective(100px);
}
.img-3 {
  position: absolute;
  display: block;
  right: -20px;
  top: -40px;
  z-index: 5;
  width: calc(60% + 20px);
  transform: translate3d(0, 0, 55px) perspective(100px) scale(1);
  opacity: 1;
  pointer-events: auto;
  transition: transform 300ms 1300ms ease, opacity 200ms 1300ms ease;
}
.pricing:checked ~ .card-3d-wrap .card-3d-wrapper .img-3 {
  opacity: 0;
  transform: translate3d(-50px, 5px, 55px) perspective(100px) scale(0.4);
  pointer-events: none;
  transition: transform 400ms ease, opacity 200ms 150ms ease;
}
.img-6 {
  position: absolute;
  display: block;
  right: -20px;
  bottom: -55px;
  z-index: 5;
  width: calc(20% + 30px);
  transform: translate3d(0, 0, 25px) perspective(100px) scale(1);
  opacity: 1;
  pointer-events: auto;
  transition: transform 300ms 1300ms ease, opacity 200ms 1300ms ease;
}
.pricing:checked ~ .card-3d-wrap .card-3d-wrapper .img-6 {
  opacity: 0;
  transform: translate3d(0, 0, 25px) perspective(100px) scale(0.4);
  pointer-events: none;
  transition: transform 400ms ease, opacity 200ms 150ms ease;
}

.img-4 {
  position: absolute;
  display: block;
  left: -60px;
  top: -60px;
  z-index: 5;
  width: calc(65% + 60px);
  transform: translate3d(0, 0, 45px) perspective(100px) scale(0.5);
  opacity: 0;
  pointer-events: none;
  transition: transform 400ms ease, opacity 200ms 150ms ease;
}
.pricing:checked ~ .card-3d-wrap .card-3d-wrapper .img-4 {
  opacity: 1;
  pointer-events: auto;
  transform: translate3d(0, 0, 45px) perspective(100px) scale(1);
  transition: transform 400ms 1200ms ease, opacity 300ms 1200ms ease;
}
.img-5 {
  position: absolute;
  display: block;
  right: -70px;
  top: -50px;
  z-index: 6;
  width: calc(60% + 70px);
  transform: translate3d(0, 0, 35px) perspective(100px) scale(0.5) rotate(0deg);
  opacity: 0;
  pointer-events: none;
  transition: transform 400ms 100ms ease, opacity 200ms 250ms ease;
}
.pricing:checked ~ .card-3d-wrap .card-3d-wrapper .img-5 {
  opacity: 1;
  pointer-events: auto;
  transform: translate3d(0, 0, 35px) perspective(100px) scale(1) rotate(10deg);
  transition: transform 400ms 1300ms ease, opacity 300ms 1300ms ease;
}
.img-7 {
  position: absolute;
  display: block;
  right: -30px;
  bottom: -65px;
  z-index: 6;
  width: calc(25% + 30px);
  transform: translate3d(0, 0, 35px) perspective(100px) scale(0.5);
  opacity: 0;
  pointer-events: none;
  transition: transform 400ms 100ms ease, opacity 200ms 250ms ease;
}
.pricing:checked ~ .card-3d-wrap .card-3d-wrapper .img-7 {
  opacity: 1;
  pointer-events: auto;
  transform: translate3d(0, 0, 35px) perspective(100px) scale(1);
  transition: transform 400ms 1300ms ease, opacity 300ms 1300ms ease;
}

.logo {
  position: fixed;
  top: 30px;
  right: 30px;
  display: block;
  z-index: 100;
  transition: all 250ms linear;
}
.logo img {
  height: 26px;
  width: auto;
  display: block;
}
.text {
  font-size: 1rem;
  line-height: 1.5;
  color: #6b7280;
}

@media (min-width: 768px) {
  .text {
    font-size: 1.125rem;
  }
}

@media (prefers-color-scheme: dark) {
  .text {
    color: #9ca3af;
  }
}

.superposicion {
  margin-top: -18rem !important;
}
.superposicion-hotel {
  margin: -18rem auto 10rem auto !important;
}
/* section home */
.section_padding_top {
  padding: 100px 0 0 0;
}
.section_heading_center {
  text-align: center;
  padding-bottom: 30px;
}
.section_heading_center h2 {
  position: relative;
  padding-bottom: 15px;
}
.section_heading_center h2:after {
  content: "";
  position: absolute;
  width: 200px;
  height: 1px;
  background: #102770;
  left: 50%;
  transform: translate(-50%, 50%);
  bottom: 0;
}
.img_animation img {
  transition: 0.4s all ease-in-out;
  position: relative;
}
.destinations_content_box {
  position: relative;
  margin-bottom: 23px;
  border-radius: 12px;
  overflow: hidden;
}
.destinations_content_inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.destinations_content_inner h2 {
  color: var(--white-color);
  padding-bottom: 20px;
}
.img_animation:hover::after {
  opacity: 1;
  visibility: visible;
}
.img_animation::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #1d3e893d;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
}
.img_animation:hover img {
  transform: scale(1.2);
}
.destinations_content_inner h3 a {
  color: var(--white-color);
}
a {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out 0.1s;
  transition: all 0.3s ease-in-out 0.1s;
  outline: 0 !important;
  color: var(--azul-gac);
}
.destinations_big_offer {
  display: flex;
}

.destinations_big_offer h1 {
  color: var(--white-color);
  font-size: 150px;
  line-height: 109px;
  padding-bottom: 20px;
}
.destinations_big_offer h6 {
  color: var(--white-color);
  line-height: 45px;
  padding-top: 8px;
}
.destinations_big_offer h6 span {
  display: block;
  font-size: 38px;
  font-weight: 500;
}
.destinations_content_inner h2 {
  color: var(--white-color);
  padding-bottom: 20px;
}
.destinations_content_box .btn {
  margin-top: 2px;
}
.btn_md {
  padding: 12px 35px;
  font-size: 18px;
}
.btn_theme {
  color: var(--white-color);
  background-color: #1d3e89;
  transition: var(--transition);
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  z-index: 0;
  border: none;
}
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  z-index: 0;
}

.content {
  display: flex;
  flex-shrink: 0;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding-bottom: 14px;
  padding-bottom: 64px;
  padding-right: 14px;
  margin-right: 10px;
  animation: scale-up-center 0.4s;
}
@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
.img-fluid {
  animation: rotate-scale-down 0.6s linear both;
}

@keyframes rotate-scale-down {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.add-Scale {
  animation: scale-up-center 0.4s;
}
.title {
  font-weight: 500;
  font-size: 4rem;
  line-height: 114%;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
}

.description {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #4b5563;
}

.img-categories {
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
  object-fit: scale-down;
  cursor: pointer;
}
.img-categories:hover {
  background-color: #00389364;
  z-index: 90;
  cursor: pointer;
}
.btn-login {
  background-color: var(--amarillo-gac);
  border-radius: 2rem;
  color: var(--azul-gac);
  font-weight: 600;
  border: none;
  padding: 3px 1rem;
  margin: 0.5rem auto;
}
.btn-viajar {
  background: var(--azul-gac);
  border-radius: 1rem;
  font-weight: 500;
  color: var(--fondo-defecto);
  border: none;
  padding: 0.7em 1rem;
  width: 200px;
  margin: 0.5rem 0;
  font-size: 0.7rem;
  box-shadow: 2px 2px 10px #ececec;
  transition: all 0.3s ease;
}

.btn-viajar:hover {
  transform: scale(1.1);
  background: var(--amarillo-gac);
  color: var(--azul-gac);
}

.video-custom {
  width: 100%;
  height: fit-content;
  background-size: cover;
}
.container-banner {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centrar verticalmente */
  align-items: center; /* Centrar horizontalmente */
  gap: 1em;
  padding: 1em;
  text-align: center;
  min-height: 100%; /* Establece una altura mínima del 100% */
}

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background-color: var(--fondo-defecto);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  background-color: var(--azul-gac);
}
.title-policy {
  color: var(--azul-gac);
  font-weight: 700;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  color: var(--bg-color);
  font-weight: 900;
  margin: 2rem;
}
.Modalcontentmod {
  background-color: transparent !important;
  border: none !important;
}
.ContentEthicalLine {
  max-width: 800px;
  background: var(--fondo-defecto);
  padding: 3em;
  border-radius: 2rem;
  margin: 180px auto;
  text-align: justify;
  border: 2px solid var(--azul-gac);
  box-shadow: 5px 5px var(--amarillo-gac);
}
.ContentEthicalLine #ptee {
  text-align: left;
}
@media (min-width: 1850px) {
  /* .container-banner{
    padding: 30% 1rem 0 20%;
  }
  .title-banner{
    font-size: 5rem;
  } */
  .container-banner p {
    font-size: 1rem;
  }
}

@media (max-width: 835px) {
  .container-banner {
    padding: 30% 0.1rem 0 11%;
  }
  .btn-login {
    margin: 1.5rem auto;
  }
}

@media (max-width: 500px) {
  .container-banner {
    padding: 0 0.3rem;
    gap: 2px;
  }
  .btn-viajar {
    padding: 3px 15px;
    font-size: 10px;
    width: 100%;
  }
  .nuestrotexto {
    font-size: 10px;
  }
  .video-custom {
    width: 100%;
    height: 190px;
  }
}
