.container-grid-hotels {
  background-color: var(--azul-gac);
  background-image: url("/src/assets/textures/our-hotels-textures.png");
  height: 130vh;
  background-size: cover;
  margin: 5% 0;
}
.layout-grid-hotel {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  grid-template-rows: 300px 300px;
}
.item-hotel {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.item-hotel:hover .img-item-hotel {
  transform: scale(1.1);
}
.two-row {
  grid-column: 2/3;
  grid-row: 1/3;
}
.two-colum {
  grid-row: 2/3;
  grid-column: 3/5;
}
.img-item-hotel {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out; /* Transición suave para el zoom */
}
.info-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--fondo-defecto);
  color: var(--azul-gac);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(100%); /* Inicialmente fuera de vista */
  transition: transform 0.3s ease-in-out;
  padding: 1em;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  gap: 3%;
}
.item-hotel:hover .info-overlay {
  transform: translateY(0); /* Muestra el overlay en hover */
}
.info-overlay h1 {
  margin: 0;
  font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (2300 - 300)));
  font-weight: 800;
}
.info-overlay span {
  font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (2300 - 300)));
  font-weight: 500;
}
.container-flag {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 6px var(--gris-gac);
  border: 1px solid rgba(102, 102, 102, 0.4);
}
.flag {
  height: 100%;
  width: 100%;
  object-fit: cover;
  padding: 2px;
  border-radius: 50%;
  background: transparent;
}
.show-more-link {
  background: var(--azul-gac);
  color: var(--fondo-defecto);
  border-radius: 0.5rem;
  width: fit-content;
  padding: 0 1em;
  border: none;
}
@media (max-width: 1000px) {
  .layout-grid-hotel {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 2000px) {
  .container-grid-hotels {
    height: 90vh;
  }
}
