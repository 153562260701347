.container-clock {
  position: fixed;
  z-index: 9;
  top: 150px;
  left: 0;
  background: rgb(0 56 147 / 1%);
  backdrop-filter: blur(1px);
}
.flip-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
}

.label-clock {
  font-size: 15px;
  font-weight: 700;
  color: white;
  margin: 10px auto;
  text-shadow: 2px 2px 2px #00b5e2, -1px -1px 0px #00b5e2;
  grid-column: span 2;
  text-align: center;
}

.clock {
  display: flex;
  justify-content: center;
  margin: 10px auto;
}
.clock .divider {
  color: var(--azul-gac);
  font-size: 36px;
  line-height: 35px;
}
.clock .flip {
  position: relative;
  margin: 2px;
  text-align: center;
  background: #00b5e2;
  border: 2px solid #00b5e2;
  border-radius: 10px;
  width: 30px;
  height: 40px;
  font-size: 16px;
  line-height: 35px;
  border-radius: 5px;
  font-weight: 700;
}
.clock .flip .digital::before,
.clock .flip .digital::after {
  position: absolute;
  content: attr(data-number);
  left: 0;
  right: 0;
  color: white;
  background: #00b5e2;
  overflow: hidden;
  perspective: 160px;
}
.clock .flip .digital::before {
  top: 0;
  bottom: 50%;
  border-bottom: 1px solid var(--fondo-defecto);
  border-radius: 10px 10px 0 0;
}
.clock .flip .digital::after {
  top: 50%;
  bottom: 0;
  line-height: 0;
  border-radius: 0 0 10px 10px;
}
.clock .flip .back::before,
.clock .flip .front::after {
  z-index: 1;
}
.clock .flip .back::after {
  z-index: 2;
}
.clock .flip .front::before {
  z-index: 3;
}
.clock .flip .back::after {
  transform-origin: center top;
  transform: rotateX(0.5turn);
}
.clock .flip.running .front::before {
  transform-origin: center bottom;
  animation: frontFlipDown 0.6s ease-in-out;
  backface-visibility: hidden;
}
.clock .flip.running .back::after {
  animation: backFlipDown 0.6s ease-in-out;
}

@keyframes frontFlipDown {
  to {
    transform: rotateX(0.5turn);
  }
}
@keyframes backFlipDown {
  to {
    transform: rotateX(0);
  }
}
.text-head-clock {
  color: var(--fondo-defecto);
  font-weight: 600;
  letter-spacing: -0.05em;
  text-shadow: 2px 2px 2px #00b5e2, -1px -1px 0px #00b5e2;
  width: 350px;
  text-align: center;
}
.container-buttons-clock {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
}
.btn-action-clock {
  border: none;
  border-radius: 2rem;
  font-weight: 800;
  display: block;
  transition: all 0.3s;
  padding: 6px 10px;
  text-align: center;
  transition: all 0.3s;
  color: var(--azul-gac);
}
.btn-action-clock.reservar {
  background: linear-gradient(233deg, #00b5e2, 10%, var(--fondo-defecto));
}
.btn-action-clock.promos {
  background: linear-gradient(235deg, var(--fondo-defecto), 80%, #00b5e2);
}
.btn-action-clock:hover {
  background: #00b5e2;
  color: var(--fondo-defecto);
  transform: scale(0.95);
}
.minifi-black-friday-container {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: #00b5e2;
  position: fixed;
  top: 150px;
  left: 0;
  z-index: 9;
  border: 3px dashed #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 800;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
}
.close-button-clock {
  right: -15px;
  position: absolute;
  background: var(--rojo-gac);
  color: var(--fondo-defecto);
  border: none;
  padding: 0.2rem;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  font-weight: 900;
}
@media (max-width: 1000px) {
  .container-clock {
    bottom: 0;
    top: inherit;
  }

  .clock .flip {
    width: 30px;
    height: 35px;
    line-height: 30px;
  }
  .clock .divider {
    font-size: 36px;
    line-height: 30px;
  }
  .close-button-clock {
    right: inherit;
    left: 0;
  }
}
