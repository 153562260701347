.container-subscribe {
  align-items: center;
  background: var(--azul-gac);
  color: var(--fondo-defecto);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  height: 140px;
  position: relative;
}

.box-subscribe {
  background: var(--fondo-defecto);
  width: 350px;
  position: absolute;
  top: -50px;
  border: 2px solid var(--azul-gac);
  height: -moz-fit-content;
  color: var(--azul-gac);
  font-weight: 900;
  right: 10%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 2em 2em 1em 2em;
}
.parallelogram {
  background: var(--amarillo-gac);
  position: absolute;
  top: -37px;
  width: 130px;
  height: 68px;
  transform: skew(295deg);
  left: 50px;
  z-index: 2;
}
.parallelogram.back {
  z-index: 1;
  left: 134px;
  background: var(--azul-gac);
  height: 45px;
  top: -25px;
}
.content-form {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
}
.content-form.icon {
  height: 20px;
  width: 20px;
}
.input-subscribe {
  border: 2px solid var(--azul-gac);
  width: 100%;
  color: var(--grid-gac);
  padding: 5px;
}
.text-subscribe {
  color: var(--azul-gac);
  font-weight: 800;
  text-transform: uppercase;
  margin: 5px 22px;
}
.btn-to-subscribe {
  background: var(--amarillo-gac);
  border: none;
  width: fit-content;
  color: var(--azul-gac);
  font-weight: bold;
  padding: 2px 5px;
  margin: 5px 0 0 0;
  align-self: flex-end;
}
.text-cta {
  display: flex;
  justify-content: center;
}
@media (max-width: 1000px) {
  .container-subscribe {
    height: 100px;
    grid-template-columns: 1fr;
    margin-top: 250px;
    align-items: normal;
  }
  .box-subscribe {
    top: -100px;
    width: 80%;
  }
  .text-cta {
    color: var(--azul-gac);
    margin: -200px auto 0 auto;
    text-align: center;
    max-width: 60%;
  }
}
