.container-upload-banners {
  max-width: 90%;
  margin: 200px auto;
  min-height: 100vh;
}
.upload-container {
  border: 2px dashed #d1d5db;
  border-radius: 0.5rem;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  height: 250px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
}

.upload-label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-icon {
  width: 48px;
  height: 48px;
  color: #9ca3af;
}

.upload-text {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #6b7280;
}

.hidden-input {
  display: none;
}
.upload-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.upload-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

  gap: 1rem;
}
.upload-image {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.link-input {
  border: 1px dashed #d1d5db;
  border-radius: 2rem;
  width: 100%;
  height: 40px;
  padding: 0.8em;
}
.link-input.textarea {
  height: fit-content;
  min-height: 100px;
  border-radius: 0.5rem;
}
.container-link {
  display: grid;
  gap: 10px;
  margin: 10px auto;
  color: var(--azul-gac);
  font-weight: 600;
}
