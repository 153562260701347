.container-text {
  position: relative;
  height: 100vh;
}
.container-text::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 56, 147, 0.75),
    rgba(0, 56, 147, 0.75)
  );
  opacity: 0.75;
}
.container-text .image {
  height: 100%;
  object-fit: cover;
}
.text-filled {
  position: absolute;
  top: var(--positionTop);
  bottom: var(--positionBottom);
  left: var(--positionLeft);
  z-index: 2;
  color: var(--textColor);
  font-size: clamp(38px, 4vw + 1rem, 128px);
  font-weight: 800;
  font-family: sans-serif;
  text-transform: uppercase;
}

.text-outlined {
  position: absolute;
  top: var(--positionTop);
  bottom: var(--positionBottom);
  left: var(--positionLeft);
  z-index: 1;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ffcf04;
  color: transparent;
  font-size: clamp(38px, 4vw + 1rem, 128px);
  text-transform: uppercase;
  font-family: sans-serif;
  font-weight: 800;
}
.image {
  display: block;
  width: 100%;
  height: auto;
  margin-top: -23px;
}
.container-questions {
  position: relative;
  min-height: fit-content;
}
.add-relative {
  position: relative;
  width: 100%;
  min-height: 300px;
  height: fit-content;
}
@media (max-width: 1000px) {
  .add-relative {
    min-height: 300px;
  }
}
