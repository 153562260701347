.header {
  background-color: rgb(0 56 147 / 70%);
  backdrop-filter: blur(4px);
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  transition: 0.6s;
  padding: 1em 5em 0 5em;
  z-index: 10;
  transition: background-color 0.5s;
}
.line-BT {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.not-show {
  display: none;
}
.header .logo1 {
  position: relative;
  font-weight: 700;
  color: var(--fondo-defecto);
  text-decoration: none;
  font-size: 2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.6s;
}
.header ul {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header ul li {
  position: relative;
  list-style: none;
}
.header ul li a {
  position: relative;
  margin: 0 15px;
  text-decoration: none;
  color: var(--fondo-defecto);
  letter-spacing: 1px;
  font-weight: 500;
  transition: 0.6s;
}
.banner-1 {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("https://placehold.co/1958x1000/75c2f0/white");
  background-size: cover;
}
.header.sticky1 .logo1,
.header.sticky1 ul li a {
  color: var(--azul-gac);
  top: 0;
  padding: 0 0 7px 0;
}

.scrolled {
  animation: scale-up-vertical-bottom 0.2s linear;
  background-color: var(--fondo-defecto);
  color: var(--azul-gac);
  top: 0;
}

@keyframes scale-up-vertical-bottom {
  0% {
    transform: scaleY(0.4);
    transform-origin: center bottom;
  }
  100% {
    transform: scaleY(1);
    transform-origin: center bottom;
  }
}

.navOptions {
  display: flex;
  justify-content: flex-end;
}

.active-menu {
  background: rgb(0 56 147 / 70%);
  border-bottom: 4px solid var(--amarillo-gac);
  padding: 25px 10px 20px 10px;
}

.active-menu-scrolled {
  border-bottom: 2px solid var(--azul-gac);
}

.btn-primary {
  background: var(--amarillo-gac);
  border: none;
  border-radius: 2em;
  padding: 0.6em 2em;
  color: var(--azul-gac);
  font-weight: 500;
}

.menu-toggle {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  color: var(--fondo-defecto);
  cursor: pointer;
}
.button1 {
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  background-color: var(--fondo-defecto);
  color: var(--azul-gac);
  border-radius: 10rem;
  font-weight: 600;
  padding: 0.4rem 1.5rem;
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 10px;
  transition: background-color 0.3s;
}

.button1__icon-wrapper {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  position: relative;
  color: var(--fondo-defecto);
  background-color: var(--azul-gac);
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.button1:hover {
  color: var(--azul-gac);
  font-weight: 700;
}

.button1:hover .button1__icon-wrapper {
  color: var(--azul-gac);
  background-color: var(--amarillo-gac);
}

.button1__icon-svg--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.button1:hover .button1__icon-svg:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.button1:hover .button1__icon-svg--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
}

@media (max-width: 768px) {
  .header {
    padding: 1em 2em;
  }

  .menu-toggle {
    display: block;
  }
  .header {
    background: rgb(0 56 147 / 70%);
    padding: 0.1rem;
    margin-top: -8px;
  }

  .header ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 80px;
    right: 0;
    background-color: var(--fondo-defecto);
    width: 100%;
    padding: 1em;
  }

  .header ul.nav-open {
    display: flex;
    background: rgb(0 56 147 / 70%);
    width: 65%;
    margin-top: -16px;
    align-items: self-start;
  }

  .header ul li {
    margin: 10px 0;
    text-align: center;
  }

  .btn-primary {
    width: 100%;
  }
  .active-menu {
    background: transparent;
    border-bottom: 2px solid var(--amarillo-gac);
    padding: 0;
  }
  .active-menu-scrolled {
    border-bottom: 2px solid var(--amarillo-gac);
  }
  .header.sticky1 .logo1,
  .header.sticky1 ul li a {
    color: var(--fondo-defecto);
  }
}
