.slider-container {
  position: relative;
  width: 100%;
  /* overflow: hidden; */
}

.slider-content {
  display: flex;
  transition: transform 0.5s ease;
  width: 100%;
}

.slide {
  min-width: 100%;
  transition: opacity 0.5s ease;
  opacity: 0;
}

.slide.active {
  opacity: 1;
}

.slider-indicators-wrapper {
  position: var(--position);
  bottom: var(--positionIndicators);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 5px;
}

.slider-indicators {
  display: flex;
  gap: 5px;
}

.indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--colorIndicator);
  cursor: pointer;
}

.indicator.active {
  background: var(--colorIndicatorActive);
}

.indicator-button {
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  height: 3pc;
}
.indicator-button svg {
  width: 30px;
  height: 30px;
}
.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: auto;
}

.slider-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slider-image {
  width: 100%;
  height: 100vh;
}

.button-slider {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}
.alternative-btn {
  color: var(--amarillo-gac);
  position: absolute;
  top: 50%;
  border: 1px solid var(--amarillo-gac);
  border-radius: 50%;
  background: rgb(0 56 147 / 70%);
  height: 50px;
  width: 50px;
  padding: 10px;
  animation: all 0.4s;
}
.alternative-btn:hover {
  border: 2px solid var(--amarillo-gac);
  transform: scale(1.2);
  background: var(--azul-gac);
}
@media (max-width: 1000px) {
  .slider-container {
    overflow: hidden;
    min-height: 300px;
  }
}
