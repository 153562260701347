/* this is a recreation of twitter search in css */
.form {
  --text-color: #0581fd;
  --active-color: var(--oscuro);
  --width-of-input: 100%;
  --inline-padding-of-input: 1.2em;
  --gap: 0.9rem;
}
/* form style */
.form {
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: var(--width-of-input);
  position: relative;
  isolation: isolate;
}
/* a fancy bg for showing background and border when focus. */
.fancy-bg {
  position: absolute;
  width: 100%;
  inset: 0;
  border-radius: 30px;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  color: var(--azul-gac);
}
/* label styling */
.label {
  width: 100%;
  padding: 0.8em;
  height: 40px;
  padding-inline: var(--inline-padding-of-input);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.search,
.close-btn {
  position: absolute;
}
/* styling search-icon */
.search {
  fill: var(--text-color);
  left: var(--inline-padding-of-input);
}
/* svg -- size */
/* svg {
  width: 17px;
  display: block;
} */
/* styling of close button */
.close-btn {
  border: none;
  right: var(--inline-padding-of-input);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  padding: 0.1em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--azul-gac);
  opacity: 0;
  visibility: hidden;
}
.close-btn:hover {
  background: var(--rojo-gac);
}
/* styling of input */
.input {
  color: var(--azul-gac);
  width: 100%;
  margin-inline: min(2em, calc(var(--inline-padding-of-input) + var(--gap)));
  background: none;
  border: none;
}

.input:focus {
  outline: none;
  color: var(--oscuro);
}

.input::-webkit-input-placeholder {
  color: var(--gris-gac);
}

.input::-moz-placeholder {
  color: var(--gris-gac);
}

.input:-ms-input-placeholder {
  color: var(--gris-gac);
}

.input::-ms-input-placeholder {
  color: var(--gris-gac);
}

.input::placeholder {
  color: var(--gris-gac);
}
/* input background change in focus */
.input:focus ~ .fancy-bg {
  background: var(--fondo-defecto);
  border: 1px solid var(--azul-gac);
}
/* search icon color change in focus */
.input:focus ~ .search {
  fill: var(--active-color);
}
/* showing close button when typing */
.input:valid ~ .close-btn {
  opacity: 1;
  visibility: visible;
}
/* this is for the default background in input,when selecting autofill options -- you can remove this code if you do not want to override the browser style.  */
.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}
.title-top {
  color: var(--gris-gac);
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}
.line-top {
  border-bottom: 4px solid var(--rojo-gac);
  border-radius: 2rem;
  width: 60%;
  margin: 0.5rem auto;
}
.hoteles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  margin: 1rem 0;
  gap: 3px;
}

.hoteles-grid img {
  max-width: 420px;
  animation: 1.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-top-right both;
}
@keyframes wipe-in-top-right {
  from {
    clip-path: polygon(-50% 50%, 50% 150%, 50% 150%, -50% 50%);
  }
  to {
    clip-path: polygon(50% -50%, 150% 50%, 50% 150%, -50% 50%);
  }
}

.b-10 {
  border-radius: 10px;
}

.name-hotel {
  background: #ed0a26;
  color: white;
  width: fit-content;
  border-radius: 5px 20px 0px 5px;
  padding: 0px 30px;
  position: relative;
  z-index: 1;
}

.p-absolute {
  position: absolute;
}

.image-container {
  position: relative;
}

.texto-encima {
  position: absolute;
  top: 93%;
  left: 18%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 14px;
  text-align: center;
  background: #ed0a26;
  width: fit-content;
  border-radius: 5px 20px 0px 5px;
  padding: 0px 30px;
}
.container-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin: 2rem 0;
}

.arrow {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  animation: all 0.3s;
  background-color: var(--gris-gac);
  color: var(--fondo-defecto);
  opacity: 0.8;
}
.arrow:hover {
  outline: 0.1em solid var(--gris-gac);
  outline-offset: 4px;
  opacity: 1;
}
.arrow:disabled {
  cursor: not-allowed;
  opacity: 0.2;
}
.arrow:hover i {
  font-size: 1.1rem;
}
.hotel_title {
  color: var(--gris-gac);
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 0px 0px #666;
}

@media (max-width: 1400px) {
  .hoteles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    margin: 1rem 0;
    gap: 3px;
  }
  .hoteles-grid img {
    max-width: 350px;
    animation: 1.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-top-right both;
  }
  .grid-container {
    padding: 1rem 0;
  }
  .grid-container div {
    margin: 0px 6px;
  }
}
@media (max-width: 835px) {
  .hoteles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    margin: 1rem 0;
    gap: 3px;
  }
  .hoteles-grid img {
    max-width: 340px;
    animation: 1.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-top-right both;
  }
  .texto-encima {
    top: 90%;
    left: 22%;
  }
}
@media (max-width: 500px) {
  .hoteles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    margin: 1rem 0.5rem;
    gap: 3px;
  }
  .hoteles-grid img {
    max-width: 100%;
    animation: 1.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-top-right both;
  }
}
