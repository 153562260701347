.faq-container {
  display: grid;
  grid-template-columns: 20% 80%;
  gap: 2rem;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto;
}

.categories {
  padding: 1px;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.container-videos-instagram {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1rem;
}
.category {
  padding: 10px 1em;
  cursor: pointer;
  border: 1px solid var(--azul-gac);
  border-radius: 2em;
  color: var(--azul-gac);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.5em;
  margin: 10px 0;
}

.category:hover {
  background-color: #f0f0f0;
}
.category.active {
  background: var(--azul-gac);
  color: var(--fondo-defecto);
}

.accordion {
  border: 2px solid var(--azul-gac);
  border-radius: 2em;
  margin-bottom: 10px;
}

.accordion-header {
  background: var(--azul-gac);
  color: var(--fondo-defecto);
  border-radius: 2em;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  text-transform: uppercase;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  padding: 0 10px;
}

.accordion-content.open {
  max-height: fit-content; /* Ajusta esta altura según sea necesario */
  padding: 1em 2em;
  color: var(--azul-gac);
}
.icon-questions {
  width: 30px;
  height: 30px;
}
.btn-add-category {
  border: 1px dashed var(--azul-gac);
  background: var(--fondo-defecto);
  border-radius: 50%;
  height: 40px;
  margin: auto 0;
  color: var(--azul-gac);
  font-weight: 700;
  transition: all 0.3s;
}
.btn-add-category:hover {
  transform: scale(1.2);
}
@media (max-width: 1000px) {
  .faq-container {
    grid-template-columns: 1fr;
    max-width: 100%;
    gap: 0;
  }
}
