.load {
  animation: spin 1s linear infinite;
  border-radius: 50%;
  border: 5px solid #f3f3f3;
  border-top: 5px solid var(--azul-gac);
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 10% auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
