.slider-video {
  display: flex;
}

.slide-item {
  height: 200px;
  scroll-snap-align: start;
  padding: 1em 2em;
  background: var(--azul-gac);
  color: var(--fondo-defecto);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  align-items: center;
}
.slide-item .title {
  font-size: calc(32px + 0.3vw);
  font-weight: bold;
}
.slide-item div {
  display: grid;
  font-size: calc(12px + 0.3vw);
  font-weight: 700;
}
.text-yellow {
  color: var(--amarillo-gac);
}

.container-contact-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 100px 0;
}

.title-article {
  color: var(--amarillo-gac);
  font-weight: 700;
  line-height: 32px;
}
.contact-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid;
  background: var(--azul-gac);
  padding: 2em;
  color: var(--fondo-defecto);
}
.contact-info.footer {
  background: var(--fondo-defecto);
  color: var(--azul-gac);
  border: none;
  padding: 0;
}

.contact-item {
  display: flex;
  align-items: flex-start;
  font-weight: 500;
}

.contact-item svg {
  margin-right: 10px;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.contact-details a:hover {
  color: var(--amarillo-gac);
}

.contact-details a {
  text-decoration: none;
  color: var(--fondo-defecto);
}
.contact-details.footer a {
  text-decoration: none;
  color: var(--azul-gac);
}

.icon-contact {
  width: 50px;
  height: 50px;
}

.icon.redes {
  border: 2px solid var(--amarillo-gac);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  transition: all 0.3s;
}
.icon.redes:hover {
  transform: translateY(-8px);
}
.container-redes {
  align-items: center;
  background: var(--azul-gac);
  display: flex;
  justify-content: space-between;
  padding: 10px 2em;
  margin: 10px 0;
}
.container-redes a {
  margin: 4px;
}

.form-contact {
  max-width: 85%;
  margin: 0 auto;
  color: var(--azul-gac);
  font-weight: 900;
  font-size: clamp(12px, 4vw + 1rem, 17px);
}
.form-field {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid var(--azul-gac);
  border-radius: 8px;
}
.form-field.invalid {
  border: 1px solid var(--rojo-gac);
}

.row-contact {
  display: flex;
  gap: 10px;
}

.row-contact .form-group {
  flex: 1;
}
.checkbox-container {
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin: 20px 0;
  min-height: fit-content;
}
.btn-contact {
  background: var(--fondo-defecto);
  border: 1px solid var(--azul-gac);
  color: var(--azul-gac);
  font-weight: 700;
  padding: 0.4em 3em;
  margin: 1em auto;
  display: flex;
  border-radius: 7px;
  font-size: clamp(14px, 4vw + 1rem, 22px);
}
.btn-contact:hover {
  box-shadow: 0px 0px 30px var(--azul-gac);
  border: 0;
}
@media (max-width: 1000px) {
  .slide-item {
    height: 400px;
    scroll-snap-align: start;
    padding: 1em;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .container-contact-section {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0;
    /* overflow: hidden; */
  }
  .form-contact {
    max-width: 100%;
    padding: 1.5rem;
  }
  .row-contact {
    flex-direction: column;
  }
  .contact-info {
    padding: 1em;
  }
}
