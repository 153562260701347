.slider3d-container {
  background: url("/public/images/categories/bg_categories.jpg");
  background-size: cover;
  min-height: 170vh;
  height: fit-content;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: monospace;
  flex-direction: column;
}

.slider3d {
  position: relative;
  width: 100%;
  height: 570px;
  perspective: 1000px;
}

.item3d {
  position: absolute;
  width: 250px;
  height: 400px;
  border-radius: 1em;
  padding: 1em;
  transition: 0.5s;
  left: calc(50% - 110px);
  top: 0;
  box-shadow: 0 0 10px rgb(16, 16, 16, 10%);
  color: var(--fondo-defecto);
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Para alinear el texto al final */
}
.item3d:not(.active3d) p {
  display: none;
}
.active3d {
  border-image: fill 0
    linear-gradient(to bottom, transparent, rgb(0, 56, 147, 80%));
  animation: scale-up-horizontal-right 0.6s;
  border-radius: 1em;
}

@keyframes scale-up-horizontal-right {
  100% {
    box-shadow: 0 0 60px var(--amarillo-gac);
  }
}
.slider3d-next,
.slider3d-prev {
  position: absolute;
  top: 40%;
  color: var(--amarillo-gac);
  background-color: transparent;
  border: none;
  font-size: xxx-large;
  font-family: monospace;
  font-weight: bold;
  width: 40px;
  height: 40px;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.4));
  cursor: pointer;
}
.slider3d-next {
  right: 8%;
}
.slider3d-prev {
  left: 8%;
  transform: rotate(180deg);
}
.title-card-3d {
  font-weight: 800;
}
.title-card-3d.active {
  color: var(--amarillo-gac);
  font-weight: 900;
  animation: tracking-in-contract 0.4s linear both;
}
@keyframes tracking-in-contract {
  0% {
    letter-spacing: 0.6em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}
.text-content {
  font-family: "Quicksand";
  line-height: 13px;
  font-size: calc(10px + (12 - 10) * ((100vw - 300px) / (2300 - 300)));
}

.container-row-hotels {
  position: relative;
}
.container-row-hotels::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to Top, transparent, var(--azul-gac));
  opacity: 0.6;
  border-radius: 0.5rem;
}
.image-item-active {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border: 8px solid var(--fondo-defecto);
  border-radius: 1em;
}

.slide-group {
  display: flex;
  gap: 20px;
  margin: 0 auto;
  justify-content: center;
}

.name-hotel-active {
  position: absolute;
  top: 20px;
  left: 20px;
  color: var(--amarillo-gac);
  font-weight: 800;
  z-index: 1;
}
@media (max-width: 1000px) {
  .slider3d-container {
    width: 100%;
    overflow: hidden;
    min-height: 170vh;
  }
  .slider3d {
    height: 450px;
    margin-top: -180px;
  }
  .slider3d-next,
  .slider3d-prev {
    top: 35%;
  }
  .slider3d-next {
    right: 0px;
    z-index: 1;
  }
  .slider3d-prev {
    left: 0px;
    transform: rotate(180deg);
    z-index: 1;
  }
}
