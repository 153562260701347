.container-affiliates {
  width: fit-content;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  box-shadow: 0 0 20px #eee;
  padding: 2rem;
  border-radius: 2rem;
  gap: 3rem;
}
.image-section-about {
  width: 100%;
  border-radius: 2rem;
}
.btn-admin-defect {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 2rem;
  background-color: var(--azul-gac);
  color: white;
  gap: 8px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s;
  width: 100%;
  margin: 10px auto;
  border: none;
  font-weight: 700;
}
.btn-admin-defect:active {
  transform: scale(0.95);
  background-color: var(--amarillo-gac);
  color: var(--azul-gac);
}
.btn-admin-defect.isLoading {
  background-color: var(--amarillo-gac);
  color: var(--azul-gac);
}
.text-button {
  font-size: calc(12px + (20 - 12) * ((100vw - 300px) / (2600 - 300)));
  font-weight: 700;
  letter-spacing: 1px;
}
.svg-admin {
  padding-top: 5px;
  height: 100%;
  width: fit-content;
}
.svg-admin svg {
  width: 50px;
  height: 30px;
}

.btn-admin-defect:hover .svg-admin svg {
  animation: jello-vertical 0.9s both;
  transform-origin: left;
}

@keyframes jello-vertical {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.preview-img {
  width: 200px;
  height: 100%;
  object-fit: contain;
}
.preview-img.includedSvg {
  background: var(--azul-gac);
}
