.section-footer {
  background: var(--fondo-defecto);
  color: var(--azul-gac);
  padding: 3rem;
  margin: 0;
}
.container-item-footer {
  display: grid;
  margin: 80px auto;
  justify-content: center;
  gap: 0px;
  line-height: 15px;
}
.fading-vertical-border {
  position: relative;
}

.fading-vertical-border::before {
  content: "";
  position: absolute;
  top: 30%; /* Para centrar el borde, empieza desde el 10% */
  left: 0;
  height: 40%; /* Altura del borde */
  width: 3px; /* Grosor del borde */
  background: linear-gradient(
    to bottom,
    rgba(0, 45, 130, 0) 0%,
    #002d82 50%,
    rgba(0, 45, 130, 0) 100%
  );
  /* Degradado que comienza transparente, tiene color en el centro y se desvanece al final */
}
.border-top-footer {
  width: 90%;
  border-top: 2px solid var(--azul-gac);
  margin: 2rem auto;
}
.border-bottom-footer {
  width: 90%;
  border-bottom: 2px solid var(--azul-gac);
  margin: 0 auto 1rem auto;
}
.round {
  background: var(--azul-gac);
  border-radius: 50%;
  color: #fff;
  padding: 0.2rem 0.4rem;
  margin: 0.2rem 0.6rem 0 0;
}
.round:hover {
  color: #fff;
  transform: scale(1.9);
}

.rnt {
  /* font-size: 9px; */
}

.icon {
  color: var(--azul-gac);
}

.text-footer a {
  color: var(--gris-gac);
  margin: 0 0.3rem;
}
.btn-bancolombia {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.3px;
  color: #2c2a29;
  background: #fdda24;
  border-radius: 100px;
  padding: 5px 7px;
  margin: 1em 0;
  transition: all 0.4s;
}
.btn-bancolombia:hover {
  transform: scale(1.1);
}
.btn-davivienda {
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #dd141d;
  background: transparent;
  border-radius: 100px;
  padding: 0.5em 3em;
  margin: 1em 0;
  border: 1px solid #dd141d;
}
.btn-davivienda img {
  width: 50px;
}
.btn-davivienda:hover {
  transform: scale(1.1);
  color: #dd141d;
  text-decoration: underline;
}
.btn-scale {
  transition: all 0.4s;
}
.btn-scale:hover {
  transform: scale(1.1);
}
@media (max-width: 1000px) {
  .section-footer {
    padding: 0.5rem;
  }
}
