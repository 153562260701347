.section-testimonials {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  width: 100%;
  overflow: hidden;
  margin: 5%;
}
.container-opinion {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.hexagon-container {
  position: absolute;
  width: 222px;
  height: 190px;
}
.hexagon-image {
  width: 100%;
  height: 100%;
}
.opinion {
  border: 6px solid var(--azul-gac);
  border-radius: 2em;
  min-height: 150px;
  width: 100%;
  color: var(--azul-gac);
  padding: 1em 2em;
  margin: 65px 0 0 170px;
}
.fw-700 {
  font-weight: 700;
}
.color-p {
  color: #444;
  font-weight: 600;
}
@media (max-width: 1000px) {
  .section-testimonials {
    grid-template-columns: 1fr;
    margin: 100px 0 0 0;
  }
  .container-opinion {
    grid-template-columns: 1fr;
  }

  .opinion {
    border: 3px solid var(--azul-gac);
    margin: 190px 0 0 0px;
    width: 30%;
  }
}
