.section-text {
  position: relative;
  height: 300px;
}

.container-about {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  max-width: 90%;
  margin: 5%;
}
.image-about {
  width: 75%;
  transform: scaleX(-1);
  margin-top: -140px;
}
.container-main-text {
  max-width: 70%;
  text-align: justify;
  color: var(--azul-gac);
}

.btn-about {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  background-color: #171717;
  padding: 1em 2em;
  border: none;
  border-radius: 0.6rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.btn-about-contact {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid var(--azul-gac);
  border-radius: 2em;
  padding: 0.5em 3em;
  color: var(--azul-gac);
  font-weight: 700;
  box-shadow: 3px 3px var(--amarillo-gac);
  font-size: clamp(15px, 4vw + 1rem, 17px);
  margin: 1em 0;
  transition: all 0.5s;
}

.btn-about-contact:hover {
  background: var(--amarillo-gac);
  box-shadow: 3px 3px var(--azul-gac);
  transform: scale(1.1);
}

.stats-banner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--fondo-defecto);
  padding: 20px;
  position: absolute;
  bottom: 15%;
  width: 100%;
  z-index: 1;
}

.stat-item {
  display: flex;
  align-items: center;
}

.icon-item-about {
  width: 70px;
  height: 70px;
  margin-right: 10px;
}

.stat-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stat-number {
  color: var(--amarillo-gac);
  font-weight: 800;
  margin: 0;
}

.stat-label {
  font-size: 1rem;
  margin-top: -10px;
  font-weight: 800;
}

.section-info-gac {
  background: var(--azul-gac);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  height: 60vh;
  margin: 10% 0;
}

.vision-section {
  background-color: var(--azul-gac);
  color: #fff;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* height: 700px;
  overflow: hidden; */
}

.vision-text {
  padding: 20px;
  text-align: justify;
  margin-left: 10%;
  margin-top: 200px;
}

.description-text {
  margin-top: 25%;
  font-size: 1rem;
  line-height: 1.5;
  padding: 2rem;
}

.description p {
  margin: 0;
}

.vision-image img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 1000px) {
  .container-about {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  .stats-banner {
    flex-direction: column;
    gap: 1rem;
    width: 75%;
  }
  .container-main-text {
    max-width: 100%;
  }
  .vision-section {
    grid-template-columns: 1fr;
  }
}
