.layout-card-blog {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); */
  height: 100vh;
  padding: 10% 0;
  gap: 1rem;
  width: 100%;
  margin: 0 auto;
  background: var(--azul-gac);
}
.card-blog {
  height: 80vh;
  position: relative;
}
.card-blog img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.text-title-blog {
  position: absolute;
  bottom: -10px;
  background: var(--azul-gac);
  border-radius: 0 6em 0em 0;
  height: fit-content;
  width: 90%;
  left: 0;
  text-align: left;
  color: var(--amarillo-gac);
  font-weight: 700;
  padding: 1em;
  text-align: center;
}
.text-title-blog.second {
  border-radius: 0 0 6em 0;
  top: 0;
}
.text-title-blog.third {
  right: 0;
  left: inherit;
  border-radius: 6rem 0 0 0;
}
.layout-grid-blog-id {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 1rem;
}
.layout-grid-blogs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 310px 310px 310px;
  gap: 1rem;
  max-width: 95%;
  margin: 2rem auto;
}
.layout-grid-blogs.second {
  grid-template-columns: repeat(5, 1fr);
}
.item-blog {
  border-radius: 0.5rem;
  height: 100%;
  position: relative;
}
.item-blog::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    to bottom,
    rgba(225, 255, 255, 0),
    var(--azul-gac)
  );
  opacity: 0.75;
  border-radius: 0.5rem;
}
.layout-grid-blogs img,
.layout-grid-blog-id img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.add-col2 {
  grid-column: span 2;
}
.add-row2 {
  grid-row: span 2;
}
.add-row3 {
  grid-row: span 2;
}
.title-blog-item {
  bottom: 0;
  color: var(--amarillo-gac);
  font-weight: 700;
  left: 10px;
  position: absolute;
  width: 60%;
  z-index: 1;
}
.title-blog-item.aside-blog {
  font-weight: 600;
  left: 10px;
  position: absolute;
  width: 90%;
  z-index: 1;
  line-height: 12px;
}
.item-category {
  color: var(--azul-gac);
  cursor: pointer;
  display: grid;
  font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (2300 - 300)));
  grid-template-columns: 90% 10%;
  text-align: left;
}
.item-category.active {
  background: #ffcf044f;
  border-radius: 2rem;
}
.value-category {
  border-radius: 1rem;
  padding: 0 0.7rem;
  background: var(--azul-gac);
  color: var(--amarillo-gac);
  font-weight: 900;
  width: fit-content;
  min-width: 40px;
  height: fit-content;
}
.container-index-blog {
  display: grid;
  gap: 20px;
  color: var(--azul-gac);
}
.text-head-componente {
  color: var(--azul-gac);
  letter-spacing: -0.05em;
  font-weight: 700;
}
.fading-border {
  position: relative;
  margin: 3rem 0 0 0;
}
.fading-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 10%; /* Para centrar el borde, empieza desde 10% */
  width: 70%; /* Ancho del borde */
  height: 2px; /* Grosor del borde */
  background: linear-gradient(
    to right,
    rgba(0, 56, 147, 0) 0%,
    var(--azul-gac) 50%,
    rgba(0, 56, 147, 0) 100%
  );
  /* Degradado que comienza transparente, tiene color en el centro y se desvanece al final */
}
.container-images-slider {
  width: 100%;
  height: auto;
}
/* OLD STYLES */
.grid-blog {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  grid-template-rows: auto auto;
  grid-gap: 1em;
  padding: 1rem;
}
.seccion1 {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  max-width: 100%;
  height: 420px;
}
.grid-blog img {
  width: 100%;
  height: 200px;
  max-height: 100%;
  object-fit: cover;
}
.degradado {
  position: relative; /* Asegura que el elemento esté posicionado de forma relativa */
}

.degradado::before {
  content: "";
  position: absolute; /* Posiciona el pseudo-elemento de forma absoluta dentro del elemento padre */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(225, 255, 255, 0),
    rgba(0, 0, 0, 0.5)
  );
}

.cardContent {
  position: relative;
  overflow: hidden;
}
.cardBlog p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #fff;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
}
.grid-row-blog {
  display: grid;
  grid-template-columns: 75% 25%;
  margin: auto;
  grid-gap: 3em;
}
.column {
  width: 100%;
}
aside {
  padding: 10px;
  text-align: left;
  color: var(--azul-gac);
  position: sticky;
  top: 7em;
}

.h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--azul-gac);
}
.line-title {
  border-bottom: 6px solid var(--rojo-gac);
  border-radius: 2rem;
}
ul {
  /* list-style-type: none; */
  padding: 0;
}

.li-custom {
  margin: 9px 0;
  list-style: none;
}

.second-grid-blog {
  display: grid;
  grid-template-columns: 36% 36% 28%;
  grid-row-gap: 20px;
  padding: 1rem;
}
.note1 {
  grid-area: 1 / 1 / 4 / 2;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  width: 90%;
  border-radius: 0.6em;
}
.note2 .card {
  grid-area: 1 / 2 / 2 / 3;
  box-shadow: 5px 3px 5px #c5c4c4;
  width: 90%;
}
.note3 .card {
  grid-area: 2 / 2 / 3 / 3;
  box-shadow: 5px 3px 5px #c5c4c4;
  width: 90%;
}
.note4 .card {
  grid-area: 3 / 2 / 4 / 3;
  box-shadow: 5px 3px 5px #c5c4c4;
  width: 90%;
}
.note5 {
  grid-area: 1 / 3 / 4 / 4;
  width: 90%;
}
.item1-sg {
  grid-row: 1 / 4;
}
.item1-sg img {
  width: 100%;
}
.card-second {
  height: 400px;
}
.title-note {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 0.1em 0.3em;
}
.limit-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  padding: 0.4em;
}
.tarjeta {
  width: 90%;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  background-color: #fff;
  min-height: 120px;
  border-radius: 0.5em;
}
.imagen {
  width: 30%;
  height: auto;
  object-fit: cover;
}
.contenido {
  flex: 1;
  padding: 1em;
  font-size: 16px;
}
.third-grid-blog {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-template-rows: auto auto auto;
  grid-gap: 1em;
  padding: 2rem;
}
.custom-card {
  width: 100%;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  border-radius: 0.6em;
}
.custom-image {
  width: 100%;
  max-height: 250px;
  object-fit: cover;
}
.custom-title {
  font-size: 18px;
  color: #333;
  padding: 1em;
}
.mySwiper .swiper-button-next:after {
  color: var(--rojo-gac);
}
.mySwiper .swiper-button-prev:after {
  color: var(--rojo-gac);
}
@media (max-width: 1000px) {
  .grid-blog {
    padding: 2rem 1rem;
  }
  .second-grid-blog {
    /* display: grid;  */
    /* grid-template-columns: 36% 36% 28%; */
    /* grid-row-gap: 20px; */
    /* padding: 1rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
  .note1 {
    width: 100%;
  }
  .tarjeta {
    margin: 0 auto;
    width: 100%;
  }
  .third-grid-blog {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
/* @media (max-width: 500px) {
  .title-about {
    font-size: 1rem;
  }
  .grid-blog {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    grid-template-rows: auto auto;
    grid-gap: 0.5em;
    padding: 1rem 0rem;
  }
  .seccion1 {
    height: auto;
  }
  .cardBlog p {
    top: 50%;
    left: 38%;
    font-size: 14px;
  }
  .grid-row-blog {
    grid-gap: 1px;
  }
  .h2 {
    font-size: 0.7rem;
    margin-bottom: 2px;
  }
  aside {
    padding: 0 0 0 10px;
  }
  .grid-row-blog {
    grid-template-columns: 75% 25%;
  }
  .li-custom {
    background-color: #f7f7f7;
    border-radius: 0.5em;
    padding: 0.2em 0.5em;
    font-size: 0.6em;
  }
  .li-custom a {
    color: var(--azul-gac);
  }
} */
/* Responsive new layout */
@media (max-width: 1000px) {
  .layout-card-blog {
    grid-template-columns: 1fr;
    height: auto;
  }

  .layout-grid-blogs {
    grid-template-columns: 1fr;
    grid-template-rows: 500px;
    max-width: 100%;
  }
  .layout-grid-blogs.second {
    grid-template-columns: 1fr;
  }
  .text-title-blog {
    font-size: 28px;
  }
  .add-col2 {
    grid-column: span 1;
  }
  .grid-row-blog {
    grid-template-columns: 100%;
  }
}
