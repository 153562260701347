.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.4rem;
  width: 90%;
  margin: 1rem auto;
}

.info-box {
  position: relative;
  border: 1px solid var(--azul-gac);
  padding: 10px;
  font-size: 14px;
}

.info-title {
  position: absolute;
  top: -20px;
  left: 10px;
  background-color: var(--azul-gac); /* Tab background color */
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
}

.info-content {
  color: var(--gris-gac);
}
.tabs {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 18px;
  color: var(--azul-gac);
}

.tab {
  padding: 10px 20px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.tab.active {
  font-weight: bold;
  position: relative;
  border: 1px solid var(--azul-gac); /* Border for active tab */
  border-bottom: none;
  color: var(--azul-gac);
}

.tab.active::after {
  content: "";
  width: 600%;
  height: 2px;
  background-color: var(--azul-gac);
  position: absolute;
  left: 100%;
  top: 100%;
  transform: translateY(-100%);
}

.tab + .tab.active::after {
  width: 500px; /* Adjust this to extend the line */
}
.container-info-hotel {
  display: grid;
  width: 90%;
  margin: 0 auto;
}

.container-map {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  gap: 1rem;
}

.zonas-interes {
  border: 1px solid var(--azul-gac);
  height: fit-content;
  width: 60%;
  border-radius: 1em 1em 0 0;
  margin: 1rem 0;
}
.title-zonas {
  background: var(--azul-gac);
  border-radius: 1em 1em 0 0;
  color: var(--fondo-defecto);
  text-align: center;
  font-weight: 700;
  margin: 0;
  padding: 1rem;
}
.zonas-interes p:nth-child(2) {
  padding: 1em;
  margin: 0;
}
.zonas-interes ul {
  list-style: none;
}

/* -_________--__ */
.title-hotel {
  position: absolute;
  font-size: 4rem;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  bottom: 3rem;
  left: 5rem;
  z-index: 1;
}
.destino {
  position: absolute;
  top: 7.3rem;
  left: 7rem;
  z-index: 1;
  background-color: var(--rojo-gac);
  color: var(--fondo-defecto);
  border-radius: 2rem;
  padding: 0.01rem 1.3rem;
}
#tag {
  position: absolute;
  top: 7rem;
  left: 5rem;
  z-index: 1;
}
.container-hotel {
  max-width: 95%;
  margin: 0 auto;
}
.text-back {
  color: var(--azul-gac);
  display: flex;
  gap: 10px;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: underline;
  letter-spacing: 0.03em;
  margin: 1rem 0 0.6rem 0;
  align-items: center;
  cursor: pointer;
}
.text-back i {
  background-color: var(--azul-gac);
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
  padding: 0.2rem;
  color: #fff;
}
.line-red-hotel {
  border-bottom: 6px solid var(--rojo-gac);
  border-radius: 2rem;
  width: 120px;
}

.icons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 1rem 0;
}
.icons img {
  width: 60px;
  height: 60px;
}

.cta-hotel {
  height: fit-content;
  width: 70%;
  background-color: var(--azul-gac);
  border-radius: 1rem;
  border: none;
  display: grid;
  grid-template-columns: auto auto;
  padding: 0.1rem;
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}

.cta-hotel p:first-child {
  color: white;
  width: 107%;
  border-radius: 2rem;
  background: var(--azul-gac);
  z-index: 1;
}
.cta-hotel p:nth-child(2) {
  background-color: var(--fondo-defecto);
  border-radius: 0 1rem 1rem 0;
  color: var(--azul-gac);
}
#descripcion {
  color: var(--gris-gac);
}

.container-info {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 2rem;
  gap: 1rem;
}
.detalles {
  width: 100%;
  border: none;
  box-shadow: 5px 5px 20px lightgray;
  border-radius: 1rem;
  padding: 1rem;
  color: var(--gris-gac);
  background-color: rgba(248, 249, 250, 1);
}
.alrededores {
  width: 90%;
  border: none;
  box-shadow: 5px 5px 20px lightgray;
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  color: var(--gris-gac);
  background-color: rgba(248, 249, 250, 1);
  justify-content: center;
  height: fit-content;
}
.alrededores img {
  height: 50px;
  width: 50px;
  margin: 0.6rem;
}
.habitaciones {
  border-radius: 1rem;
  box-shadow: 10px 5px 22px lightgray;
  width: 90%;
  margin: 0 0 0 10%;
  height: fit-content;
  padding: 1rem;
}

.custom-img {
  height: 300px;
  border-radius: 1rem;
  object-fit: revert;
  width: 520px;
}

.map {
  border-radius: 1rem;
  width: 90%;
  margin: 1rem 0 0 10%;
  height: fit-content;
  padding: 1rem;
}
.map iframe {
  box-shadow: 10px 5px 22px lightgray;
}

@media (max-width: 835px) {
  .title-hotel {
    top: 16rem;
    left: 5rem;
    font-size: 2rem;
  }
  .container-hotel {
    padding: 1rem;
  }
  .habitaciones {
    width: 100%;
    margin: 1rem 0;
  }
  .map {
    width: 100%;
    padding: 0;
    margin: 1.3rem 0;
  }
  .map iframe {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .container-info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    margin-top: 2rem;
    gap: 1rem;
  }
  .destino {
    top: 5.3rem;
    left: 3rem;
    font-size: 12px;
  }
  #tag {
    top: 5rem;
    left: 1rem;
  }
  .title-hotel {
    top: 10rem;
    left: 3rem;
    font-size: 1rem;
  }
  .cta-hotel {
    width: 100%;
  }
}
@media (min-width: 1200px) and (max-width: 1580px) {
  .title-hotel {
    top: 8em;
    bottom: 0;
    font-size: 3rem;
  }
  .container-hotel {
    padding: 1rem 4rem;
  }
}
@media (min-width: 1901px) {
  .title-hotel {
    bottom: 14rem;
  }
}
