.image-slider-main {
  width: 100%;
}
/* Aplica el efecto de reflejo en un elemento con una clase específica */
.reflejo-arriba {
  -webkit-box-reflect: above 0px
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.7)
    );
}

.add-margin {
  margin-top: 136px;
}
@media (max-width: 1000px) {
  .add-margin {
    margin-top: 116px;
  }
}
