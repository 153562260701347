.skeleton {
  background-color: #e0e0e0; /* Color de fondo base */
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

/* Efecto de brillo animado */
.skeleton::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%; /* Inicia fuera del contenedor */
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.6),
    /* Blanco semi-transparente */ transparent
  );
  animation: shine 1.5s infinite;
}

@keyframes shine {
  0% {
    left: -100%; /* Comienza fuera del contenedor */
  }
  100% {
    left: 100%; /* Termina fuera del contenedor */
  }
}

/* Estilos específicos para diferentes tipos de Skeleton */
.skeleton.text {
  height: 1rem;
}

.skeleton.image {
  width: 100%;
  height: 200px;
  border-radius: 8px;
}

.skeleton.button {
  width: 100px;
  height: 40px;
  border-radius: 20px;
}
