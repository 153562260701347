.container-admin {
  margin: 240px auto 240px auto;
  min-height: 100vh;
}
.custom-table {
  min-width: 80%;
  text-align: center;
  margin: 0 auto;
}
.img-table {
  height: 200px;
  width: auto;
  border-radius: 0.5em;
}
.card-delete {
  background: var(--fondo-defecto);
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  color: #2c3e50;
  margin: 20% auto;
  height: -webkit-fit-content;
  height: fit-content;
  min-height: 170px;
  min-width: 250px;
  padding: 2rem;
  width: -webkit-fit-content;
  width: fit-content;
  position: relative;
}
.btn-action {
  background: #ff3e4e;
  border: none;
  border-radius: 12px;
  color: var(--fondo-defecto);
  font-weight: 500;
  padding: 0.4rem 2rem;
  margin: 0 1rem;
}
.btn-action.cancel {
  background: #f0f0f0;
  color: #000000;
}

.btn-options-table {
  padding: 10px;
  border-radius: 12px;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}
.btn-options-table svg {
  width: 20px;
  height: 20px;
}

.btn-options-table:hover {
  box-shadow: 0 10px 20px -10px rgba(136, 135, 135, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}
.btn-options-table.edit {
  background: var(--azul-gac);
}

.btn-add-banner {
  margin: 20px auto;
  display: flex;
  width: fit-content;
}
.icon-modal-close {
  color: var(--fondo-defecto);
  height: 40px;
  width: 40px;
  position: absolute;
  top: -14px;
  right: -10px;
  background: var(--rojo-gac);
  border-radius: 50%;
  padding: 0.4rem;
  cursor: pointer;
}
